import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { reportGrapeHelpers } from "../../helpers";
import CommonLoader from "../Loaders/loader";

const ProjectStatus = ({ projectStatusPipeData, isLoading }) => {
  const { projectStatusHelper } = reportGrapeHelpers;
  const chartData = projectStatusHelper(projectStatusPipeData);
  const { t } = useTranslation();
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  useEffect(() => {
    if (
      !isLoading &&
      (!projectStatusPipeData || projectStatusPipeData.length === 0)
    ) {
      setShowNoDataMessage(true);
    } else {
      setShowNoDataMessage(false);
    }
  }, [isLoading, projectStatusPipeData]);

  return (
    <>
      {isLoading ? (
        <div className="initial-sequence-load">
          <CommonLoader />
        </div>
      ) : (
        <div className="project-status">
          <div className="pipe-status-tab">
            <span className="pipeStatusHeader">
              {t("projectStatus.TotalValidPipes")} :&nbsp;
              <span className="data">{chartData.validPipes}</span>
            </span>
          </div>
          {projectStatusPipeData && projectStatusPipeData.length > 0 && (
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={312}
            />
          )}

          {showNoDataMessage && (
            <p className="no-data-projectStatus">{t("pipeDataGraph.noData")}</p>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectStatus;
